import { SauceTv as SauceTvComponent } from '@components/common/Sauce'
import { getConfigByLocale } from '@lib/locale'
import { useRouter } from 'next/router'
interface Props {
  slice: any
}
const SauceTv = ({ slice }: Props) => {
  const { locale } = useRouter()
  const primary = slice?.primary
  const sauceTvHandle = primary?.saucetv_handle
  const sauceId = getConfigByLocale(locale, 'sauceId')
  if (!sauceTvHandle || !sauceId) return <></>
  return (
    <div className="sauceTv">
      <section key={`${sauceTvHandle}-${locale}`}>
        <SauceTvComponent handle={sauceTvHandle} />
      </section>
    </div>
  )
}

export default SauceTv
