import { getConfigByLocale } from '@lib/locale'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'

interface Props {
  handle: string
}

const TV = ({ handle }: Props) => {
  const { locale, events, pathname } = useRouter()
  const containerRef = useRef<any>(null)
  const [shouldLoadWidget, setShouldLoadWidget] = useState(true)
  const sauceId = getConfigByLocale(locale!, 'sauceId')
  const removeLoadedSauceTvContainer = (
    elements: HTMLCollectionOf<Element>
  ) => {
    const elementsArray = Array.from(elements)
    elementsArray.forEach((element) => {
      element.remove()
    })
  }
  useEffect(() => {
    if (!containerRef || !containerRef.current || !handle || !shouldLoadWidget)
      return
    const container = containerRef.current
    if (container) {
      while (container.firstChild) {
        container.removeChild(container.firstChild)
      }
      const scriptTag = document.createElement('script')
      scriptTag.src = `//app.addsauce.com/widgets/tv/${handle}/sauce_general.js`
      scriptTag.async = true
      scriptTag.setAttribute('class', 'snapppt-widget')
      scriptTag.setAttribute(
        'data-custom-visit-url',
        `/${locale}/product/PRODUCT_HANDLE`
      )
      container.appendChild(scriptTag)
    }
  }, [containerRef, handle, sauceId, locale, shouldLoadWidget])
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url === '/' || /\/[a-z]{2}-[a-z]{2}\/?$/.test(url)) {
        setShouldLoadWidget(true)
      } else {
        setShouldLoadWidget(false)
        removeLoadedSauceTvContainer(
          document.getElementsByClassName('snptwdgt-sauce-tv-wrappr')
        )
      }
    }
    events.on('routeChangeStart', handleRouteChange)
    return () => {
      events.off('routeChangeStart', handleRouteChange)
    }
  }, [events])
  useEffect(() => {
    setShouldLoadWidget(pathname === '/')
  }, [pathname])
  return <div className="snpt-wdgt--saucetv" ref={containerRef} />
}

export default TV
